<template>
    <div style="width:100vw;margin:-12px;">
        <!-- app bar -->
        <top-nav title="Clientes"></top-nav>

        <div v-if="filtered" class="pt-5">
            <v-text-field :value="q" class="rounded-xl mb-3 mx-3 mt-2" flat solo background-color="grey lighten-3" label="Buscar" 
                hide-details dense prepend-inner-icon="mdi-magnify" clearable @input="setQuery" @click:clear="setQuery('')"></v-text-field>
            <div class="d-flex align-center my-3 mx-3">
                <v-chip @click="setActive( !isActive )" class="mr-3">
                    <v-icon v-if="isActive" small class="mr-2">mdi-account-check</v-icon>
                    <span>Activos</span>
                </v-chip>
                <v-menu bottom left offset-y>
                    <template v-slot:activator="{ on, attrs }">
                        <v-chip v-bind="attrs" v-on="on">
                            <v-icon :color="getVacuIDStatusColor(filterBy)" v-text="filterIcon" small></v-icon>
                            <span v-text="filterText" class="ml-3"></span>
                        </v-chip>
                    </template>

                    <v-list>
                        <v-list-item v-for="(item, i) in filterItems" :key="`sort-item-${i}`" :disabled="filterBy === item.value" @click="setFilterBy( item.value )" dense>
                            <v-list-item-icon class="mx-0">
                                <v-icon :color="getVacuIDStatusColor(item.value)" v-text="item.icon" small></v-icon>
                            </v-list-item-icon>
                            <v-list-item-title v-text="item.text"></v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </div>
            <div v-if="q && filtered.length === 0" class="d-flex justify-center align-center" style="width: 100%;line-break:anywhere;">
                <v-icon class="mr-2">mdi-alert-circle-outline</v-icon>
                <span>No se encontraron resultados para <strong>"{{ q }}"</strong></span>
            </div>
            <div v-else-if="q" class="d-flex justify-center align-center" style="width: 100%;line-break:anywhere;">
                <span v-if="!loading">Mostrando {{ filtered.length }} resultados para <strong>"{{ q }}"</strong></span>
                <span v-else>Buscando resultados para <strong>"{{ q }}"</strong></span>
            </div>
            <!-- content -->
            <v-list two-line>
                <template v-for="(customer, i) in filtered">
                    <v-list-item :key="`customer-${i}`" @click.prevent.stop="$router.push('/secure/customer/profile/' + customer.id)" :ripple="false">
                        <v-list-item-avatar>
                            <!-- <v-img v-if="customer.profilePic != null && customer.profilePic.key.length > 0" :src="getProfilePic(customer)"></v-img> -->
                            <v-avatar color="accent" size="36" class="white--text">{{ getCustomerInitials( customer ) }}</v-avatar>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title class="text-left">
                                {{ `${customer.firstName} ${customer.lastName}` }}
                                <!-- <v-icon small :color="getVacuIDIconColor(customer)" v-text="getVacuIDIcon(customer)" class="mr-1"></v-icon> -->
                            </v-list-item-title>
                            <v-list-item-subtitle>{{ customer.email }}</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-btn icon :to="`/secure/customer/${customer.id}`" @click.stop>
                                <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                        </v-list-item-action>
                    </v-list-item>
                </template>
            </v-list>
            <v-progress-circular v-if="loading" style="position:absolute; bottom: 25px; left: 50%; transform: translateX(-50%)" :loading="loading" :size="50" color="primary" indeterminate ></v-progress-circular>

            <v-btn color="primary" dark fixed bottom right fab class="addBtn" to="/secure/customer">
                <v-icon>mdi-plus</v-icon>
            </v-btn>
        </div>

        <v-skeleton-loader class="mx-auto" type="list-item-avatar-two-line, list-item-avatar-two-line, list-item-avatar-two-line, list-item-avatar-two-line" v-else></v-skeleton-loader>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import TopNav from '@/components/admin/TopNav';
import { PetuCustomer } from '@/classes/customer'
import { _st } from '@/softech';

export default {
    data:() => ({
        loading: true,
        height: false,
        isCheckins: false,
        fromMenu: false,
        toMenu: false,
        from: null,
        to: null,
        lock: true,
        filterItems: [
            { text: 'N/A', value: 0, icon: 'mdi-cancel' },
            { text: 'Tiene Vacu ID', value: 1, icon: 'mdi-check-circle' },
            { text: 'No Tiene Vacu ID', value: 2, icon: 'mdi-alert' },
        ],
        filtered: []
    }),
    computed: {
        ...mapGetters({ 
            user        : 'auth/user',
            items       : 'customersInfo/customers',
            q           : 'customersInfo/query',
            isActive    : 'customersInfo/isActive',
            filterBy    : 'customersInfo/filterBy'
        }),
        // filteredCustomers() {
        //     let customers = this.isActive
        //         ? this.items.filter(c => c.active) 
        //         : this.items;

        //     return customers.filter(c => c.vacuId?.key.length > 0);
        // },
        // resultText: ({ filteredCustomers }) => filteredCustomers.length === 1 ? 'resultado' : 'resultados',
        filterText: ({ filterItems, filterBy }) => filterItems.find((i) => i.value === filterBy).text,
        filterIcon: ({ filterItems, filterBy }) => filterItems.find((i) => i.value === filterBy).icon,
        customers() {
            // if( this.q == '' )
            //     return this.items;


            // return this.items.filter(c => {
            //     return c.filterString.includes( this.q ) 
            //             || c.email.includes( this.q );
            // });

            //  ------------------

            // let items = this.items;

            // if( this.q.length == 0 && !this.isActive && this.filterBy == 0 )
            //     return items;
            
            // if( this.isActive )
            //     items = items.filter(c => c.active);

            // if( this.filterBy == 1 ) {
            //     items = items.filter(c => c.vacuId.key.length > 0);
            // }
            // else if( this.filterBy == 2 ) {
            //     items = items.filter(c => c.vacuId.key.length == 0);
            // }

            // if( this.q.length == 0 )
            //     return items;

            // let q = this.q?.toLowerCase()?.trim() || '';
            // if( !_st.isNUE( q ) ) {
            
            //     items = items.filter(c => `${c.firstName} ${c.lastName}`.toLowerCase().includes( q ) 
            //         || c.email.includes( q ) 
            //         || c.phone.includes( q )
            //     );
            // }

            // return items;
        }
    },
    watch: {
        q() {
            if( this.q?.length % 3 == 0 )
                this.filter();
        },
        isActive() {
            this.filter();
        },
        filterBy() {
            this.filter();
        }
    },
    async mounted() {
        // this.getNextCustomers()
        await this.loadInitialCustomers();
        this.filter();
    },
    methods: {
        ...mapActions({ 
            getCustomers: 'customersInfo/getCustomers',
            setQuery    : 'customersInfo/setQuery',
            setActive   : 'customersInfo/setActive',
            setFilterBy : 'customersInfo/setFilterBy'
        }),
        async loadInitialCustomers() {// : _.debounce(async function() {
            this.loading = true;

            if( this.items.length == 0 )
                await this.getCustomers();

            // Correr una sola vez para actualizar clientes, eliminar limit
            // console.log(this.customers)
            // for (let c of this.customers) {
            //     if (!c.searchString) {
            //         const cu = new PetuCustomer()
            //         await cu.load(c.id)
            //         await cu.save()
            //     }
            // }
            // 
            this.loading = false;
            this.lock = false;
        },
        filter() {
            this.filtered = this.items;

            if( this.q?.length > 0 ) {
                this.filtered = this.items.filter(c => {
                    return c.filterString.includes( this.q ) 
                        || c.email.includes( this.q );
                });
            }

            if( this.isActive )
                this.filtered = this.filtered.filter(c => c.active);

            if( this.filterBy == 1 ) {
                this.filtered = this.filtered.filter(c => c.vacuId.key.length > 0);
            }
            else if( this.filterBy == 2 ) {
                this.filtered = this.filtered.filter(c => c.vacuId.key.length == 0);
            }
        },
        // getNextCustomers: _.debounce(function () {
        //     window.onscroll = async () => {
        //         let bottom = document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight;
        //         if (bottom && !this.lock) {
        //             this.loading = true
        //             let { items, nextToken } = await this.getCustomers({ q: this.q })
        //             this.loading = false
        //             this.customers = this.customers.concat(items)
        //             if (!nextToken) this.lock = true
        //         }
        //     }
        // }, 1000),
        getCustomerInitials( c ) {
            return c.firstName && c.lastName
                ? `${c.firstName[0].toUpperCase()}${c.lastName[0].toUpperCase()}`
                : '';
        },
        getVacuIDIcon(customer) {
            return this.hasVacuID(customer) ? 'mdi-check-circle' : 'mdi-alert'
        },
        getVacuIDIconColor(customer) {
            return this.hasVacuID(customer) ? 'success darken-1' : 'orange'
        },
        hasVacuID(c) {
            return c.vacuId?.key.length > 0;
            // let c = new PetuCustomer(customer)
            // return c.hasVacuID()
        },
        // async deleteCustomer(customerId) {
        //     const c = new PetuCustomer()
        //     await c.load(customerId)
        //     await c.delete()
        //     this.loadCustomers()
        // },
        filterByVacuIDStatus(c) {
            switch (this.filterBy) {
                case(1): return this.hasVacuID(c)
                case(2): return !this.hasVacuID(c)
                default: return true
            }
        },
        filterByActiveSubscription(c) {
            return c.active === true;
        },
        getVacuIDStatusColor(value) {
            switch (value) {
                case(1): return 'success darken-1'
                case(2): return 'orange'
                default: return ''
            }
        },
        getProfilePic(c) {
            if( c.profilePic == null || c.profilePic.key.length == 0 ) 
                return '';

            const cc    = new PetuCustomer(c);
            return cc.getProfilePicSrc();
        }
    },
    components: { TopNav }
}
</script>

<style lang="scss" scoped>
.addBtn {
    bottom: 15px !important;
}
</style>